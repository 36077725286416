@media (max-width: 1024px) {

  .container {
    width: 80%;
  }
  #hero .hero__img__ambulance {
    width: 444px;
    margin-left: 9px;
    margin-top: 102px;
&__car{
  width: 444px;
  margin-top: 50px;
}
    @keyframes ambulanceKey {
      from {
        margin-left: 900px;
      }
      to {
        margin-left: 9px;
      }
    }
  }
  .flasher{
    margin-left: 133px;
    margin-top: 54px;
    width: 142px;
  }
  .Flasher2{
    width: 60px;
  }
  .Flasher{
    width: 50px;
  }
  #hero .hero__img__circle {
    width: 435px;
    height: 435px;
    margin-left: 33px;
    margin-top: 90px;
  }
  #card .card__Title {
    font-size: 25px;
  }
  #card .card__MinCard {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #connection .connection__our__contact {
    width: 164px;
    height: 372px;
  }
  #connection .connection__our__google {
    width: 474px;
    height: 472px;
  }
  #connection .connection__our__pro {
    width: 256px;
  }
  #connection .connection__our__contact__logos__titles {
    font-size: 16px;
  }
  #connection .connection__our__contact__logos__title {
    font-size: 12px;
  }
  #hero .hero__title__Title {
    font-size: 28px;
    width: 439px;
  }
  #hero .day__div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 154px;
    height: 144px;
    background: #FFFFFF;
    box-shadow: -5.42092px 5.42092px 9.03487px rgb(0 0 0 / 17%);
    border-radius: 50px;
    margin: 0 0 20px 0;
    padding: 10px;
  }
}
@media (max-width: 768px) {
.Modal{
  display: block;
  position: fixed;
}
  #card .card {
    background: white;
  }
  #card .card__mather {
    display: block;
  }
  #card .card__mather {
    display: flex;
    justify-content: center;
  }

  #hero .hero__img__ambulance {
    width: 321px;
    margin-left: -75px;
    &__car{
      width: 261px;
      margin-left: 23px;
      margin-top: 112px;
    }
    @keyframes ambulanceKey {
      from {
        margin-left: 900px;
      }
      to {
        margin-left: -75px;
      }
    }
  }
  .flasher {
    margin-left: 100px;
    margin-top: 110px;
    width: 86px;
  }

  #hero .hero__img__circle {
    width: 265px;
    height: 265px;
    margin-left: -18px;
    margin-top: 157px;
  }

  #connection .connection__our__pro {
    /* width: 53px; */
    display: none;
  }
  #connection .connection {

    padding: 0;
  }
  #connection .connection__our__google {
    width: 618px;
    height: 472px;
  }

  #footer .footer__title {
    width: 169px;
    font-size: 18px;
  }
  #footer .footer__tel {
    font-size: 18px;
   }
  #hero .day {
display: none;
  }

}
@media (max-width: 576px) {
  .container{
    overflow: hidden;
    padding: 57px;
  }
  #hero {
    background: url("../img/Rectangle 9.png") no-repeat center/cover;
  }
  #hero .hero__img__circle {
    width: 300px;
    height: 300px;
    margin-left: -226px;
    margin-top: 247px;
   }
  #hero .hero__title__btn {
    margin: 250px 0 50px 0;
  }
  #hero .hero__img__ambulance {
    position: relative;
    width: 428px;
    margin-left: -285px;
    margin-top: 108px;
    &__car{
      width: 327px;
      margin-left: -57px;
      margin-top: 172px;
    }
    @keyframes ambulanceKey {
      from {
        margin-left: 900px;
      }
      to {
        margin-left: -285px;
      }
    }
  }
.flasher{
  margin-left: 35px;
  margin-top: 169px;
  width: 121px;
}

  #footer .footer{
    flex-direction: column;
  }
}
@media (max-width: 540px) {
  .container{
     padding: 0 49px 0 49px;
  }
}
@media (max-width: 450px) {
  .container{
    overflow: hidden;
     padding: 0 40px 0 40px;
  }
  #card .card__MinCard__cards {
 display: none;
  }
  #card .card__Accordions{
    display: block;
  }
  #hero .hero__title__logo {
     justify-content: center;
    flex-direction: column;
  }
  #hero .hero__title__Title {
    font-size: 20px;
    width: 335px;
    text-align: center;
  }
  #hero .hero__img__ambulance {
    margin-top: 145px;
  }
  #hero .hero__img__circle {
    margin-left: -188px;
    margin-top: 280px;
  }
  #hero .hero__title__btn {
    margin: 304px 0 50px 0;
  }
  #connection .connection__our {
    display: flex;
    flex-direction: column;
  }
  #connection .connection__our__google {
    width: 343px;
    height: 257px;
    margin: 10px 0 10px 0;

  }

  #connection .connection__our__contact {
    width: 236px;
    height: 331px;
    position: relative;
  }
}
@media (max-width: 428px) {
  #hero .hero__img__circle {
    margin-left: -243px;
  }
}
@media (max-width: 425px) {

  #hero .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #hero .hero__img__circle {
    margin-left: 0;
    margin-top: -415px;
  }
  #hero .hero__title__row {
    margin-left: 62px;
  }

  #hero .hero__title__btn {
    margin: 315px 0 25px 63px;
  }
  #hero .hero__img__ambulance {
     position: relative;
    width: 394px;
    margin-left: 123px;
    margin-top: -545px;
    @keyframes ambulanceKey {
      from {
        margin-left: 900px;
      }
      to {
        margin-left: 123px;
      }
    }
  }
  #hero .hero__img__ambulance__car {
     margin-left: -27px;
   }
  #card .card__Title {
    font-size: 15px;
    text-align: center;
  }
  #card .card__MinCard__cards {
    width: 288px;
    height: 250px;
    border-radius: 12px;
    padding: 20px;
   }
  #card .card__MinCard__cards__p3 {
    font-size: 12px;
  }
  #connection .connection__title {
    font-size: 27px;
    text-align: center;
    }





}
@media (max-width: 400px) {
  .container{
    padding: 38px;
  }
}
@media (max-width: 390px) {
  .container{
     padding: 35px;
   }
  #hero .hero__img__ambulance__car {
    margin-left: -23px;
  }
  .flasher {
    margin-left: 67px;
  }
}
@media (max-width: 375px) {
  .container{
    padding: 35px;
  }
}
@media (max-width: 360px) {
  .container{
    padding: 32px;
  }
  #hero .hero__img__ambulance__car {
    width: 286px;
    margin-left: -16px;
    margin-top: 172px;
  }
  .flasher {
    margin-left: 64px;
    width: 107px;
  }
  .Flasher {
    width: 40px;
  }
  .Flasher2 {
    width: 48px;
  }
  #hero .hero__img__circle {
    width: 270px;
    height: 270px;
  }
}
@media (max-width: 320px) {
  .container{
    padding: 30px;
  }
}



















